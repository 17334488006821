<template>
  <div>
    <b-container fluid>
      <b-row style="padding-top: 15px">
        <b-col cols="12" md="12" lg="8" xl="8">
          <div
            style="
              width: 100%;
              height: 40px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              background-color: ;
            "
          >
            <h6
              style="font-weight: 700; color: #9c4098; font-size: 22px; margin-bottom: 0"
            >
              Buat Baru Keterangan Laporan Kematian
            </h6>
          </div>
        </b-col>
        <b-col cols="12" md="12" lg="4" xl="4">
          <div style="display: flex; justify-content: flex-end">
            <b-button variant="primary" @click="pindahList()">
              <h6 class="m-0 p-0 text-light">
                <b-icon icon="list-task"></b-icon>
                Lihat Semua
              </h6>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" lg="12" xl="12" class="p-0">
          <hr />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-card>
            <b-row>
              <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                <b-form-group label-cols-md="4">
                  <template v-slot:label> Cari Pasien </template>
                  <Multiselect
                    v-model="pencari_data"
                    :options="option_cari_pasien"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="eRm / NIK / No. Asuransi Pasien"
                    size="sm"
                    track-by="rm_id"
                    label="nama_lengkap"
                    :loading="is_loading"
                    :internal-search="false"
                    @search-change="cekCariPasien"
                  >
                    <template #no-result>
                      Data pasien tidak ditemukan.
                    </template>
                  </Multiselect>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label> ID <sup class="text-danger">*</sup> </template>
                  <b-form-input
                    v-model="is_data.tipe_id"
                    type="text"
                    disabled
                  ></b-form-input>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label> NIK </template>
                  <!-- <Multiselect
                    v-model="is_data.nik_alm"
                    :options="option_nik"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="No. Induk Kependudukan"
                    size="sm"
                  ></Multiselect> -->
                  <b-form-input
                    v-model="is_data.nik_alm"
                    type="text"
                  >
                  </b-form-input>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label> No. KK </template>
                  <Multiselect
                    v-model="is_data.kk_alm"
                    :options="option_kk"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="No. Kartu Keluarga"
                    size="sm"
                  ></Multiselect>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Nama Pasien <sup class="text-danger">*</sup>
                  </template>
                  <b-form-input
                    v-model="is_data.nama_alm"
                   type="text"></b-form-input>
                </b-form-group>

                <!-- <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Nama Kepala Keluarga <sup class="text-danger">*</sup>
                  </template>
                  <b-form-input type="text"></b-form-input>
                </b-form-group> -->

                <b-form-group label-cols-md="4">
                  <template v-slot:label> Jenis Kelamin </template>
                  <b-form-radio-group id="" v-model="is_data.jenis_kelamin_alm">
                    <b-form-radio value="L" class="mt-1">
                      <span>Laki-laki</span>
                    </b-form-radio>
                    <b-form-radio value="P">
                      <span>Perempuan</span>
                    </b-form-radio>
                  </b-form-radio-group>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Tempat Lahir <sup class="text-danger">*</sup>
                  </template>
                  <b-form-input 
                    v-model="is_data.tempat_lahir_alm"
                    type="text"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Tanggal Lahir <sup class="text-danger">*</sup>
                  </template>
                  <DatePicker
                    v-model="is_data.tanggal_lahir_alm"
                    format="YYYY-MM-DD"
                    type="date"
                    placeholder="-- Pilih tanggal --"
                    style="width: 100%;"
                  ></DatePicker>
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Provinsi <sup class="text-danger">*</sup>
                  </template>
                  <Multiselect
                    v-model="obj_provinsi"
                    :options="option_prov"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="-- Pilih --"
                    size="sm"
                    track-by="ms_provinsi_id"
                    label="nama_provinsi"
                    @select="getKota($event)"
                  ></Multiselect>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Kota/Kab <sup class="text-danger">*</sup>
                  </template>
                  <Multiselect
                    v-model="obj_kota"
                    :options="option_kabkot"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="-- Pilih --"
                    size="sm"
                    track-by="ms_kota_id"
                    label="nama_kota"
                    @select="getKecamatan($event)"
                  ></Multiselect>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Kecamatan <sup class="text-danger">*</sup>
                  </template>
                  <Multiselect
                    v-model="obj_kecamatan"
                    :options="option_kec"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="-- Pilih --"
                    size="sm"
                    track-by="ms_kecamatan_id"
                    label="nama_kecamatan"
                    @select="getKelurahan($event)"
                  ></Multiselect>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Kelurahan/Desa <sup class="text-danger">*</sup>
                  </template>
                  <Multiselect
                    v-model="obj_kelurahan"
                    :options="option_keldes"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="-- Pilih --"
                    track-by="ms_kelurahan_id"
                    label="nama_kelurahan"
                    size="sm"
                  ></Multiselect>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label> Dusun </template>
                  <b-form-input 
                    v-model="is_data.nama_dusun_alm"
                    type="text"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Alamat <sup class="text-danger">*</sup>
                  </template>
                  <b-form-textarea 
                    v-model="is_data.alamat_alm"
                    rows="3"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <hr />
              </b-col>
            </b-row>

              <!-- ======================== PERNYATAAN MENINGGAL ======================== -->
              <b-row>
              <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                <h6 class="custom-title">Telah dinyatakan meninggal pada</h6>
                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Tanggal Meninggal <sup class="text-danger">*</sup>
                  </template>
                  <DatePicker
                    v-model="is_data.data_pelapor.tanggal_meninggal"
                    format="YYYY-MM-DD"
                    type="date"
                    placeholder="-- Pilih tanggal --"
                    style="width: 100%;"
                  ></DatePicker>
                </b-form-group>
                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Jam Meninggal <sup class="text-danger">*</sup>
                  </template>
                  <DatePicker
                    v-model="is_data.data_pelapor.jam_meninggal"
                    format="HH:mm"
                    type="time"
                    placeholder="-- Pilih jam --"
                    style="width: 100%;"
                  ></DatePicker>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label> Lokasi Meninggal <sup class="text-danger">*</sup> </template>
                  <b-form-input
                    v-model="is_data.data_pelapor.lokasi_meninggal"
                    type="text" 
                    placeholder="Lokasi meninggal"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Pada Usia <sup class="text-danger">*</sup>
                  </template>
                  <b-form-input 
                    v-model="is_data.data_pelapor.usia_meninggal"
                    type="text" 
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <!-- ========================== DATA PELAPOR ========================== -->
              <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                <h6 class="custom-title">Data Pelapor</h6>
                <b-form-group label-cols-md="4">
                  <template v-slot:label> NIK <sup class="text-danger">*</sup> </template>
                  <!-- <Multiselect
                    v-model="is_data.data_pelapor.nik_pelapor"
                    :options="option_nik_pelapor"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="-- Pilih --"
                    size="sm"
                  ></Multiselect> -->
                  <b-form-input v-model="is_data.data_pelapor.nik_pelapor" type="text"></b-form-input>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Nama <sup class="text-danger">*</sup>
                  </template>
                  <b-form-input v-model="is_data.data_pelapor.nama_pelapor" type="text"></b-form-input>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Tempat Lahir <sup class="text-danger">*</sup>
                  </template>
                  <b-form-input 
                    v-model="is_data.data_pelapor.tempat_lahir_pelapor" 
                    type="text"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Tanggal Lahir <sup class="text-danger">*</sup>
                  </template>
                  <DatePicker
                    v-model="is_data.data_pelapor.tanggal_lahir_pelapor"
                    format="YYYY-MM-DD"
                    type="date"
                    placeholder="-- Pilih tanggal --"
                    style="width: 100%;"
                  ></DatePicker>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label> Pekerjaan </template>
                  <b-form-input 
                    v-model="is_data.data_pelapor.pekerjaan_pelapor"
                    type="text"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Alamat <sup class="text-danger">*</sup>
                  </template>
                  <b-form-textarea 
                    v-model="is_data.data_pelapor.alamat_pelapor"
                    rows="3"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Hubungan <sup class="text-danger">*</sup>
                  </template>
                  <b-form-input
                    v-model="is_data.data_pelapor.hubungan_pelapor"
                    type="text"
                    placeholder="Hubungan dengan Almarhum"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <hr />
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <div style="width: 100%; display: flex; justify-content: flex-end">
                  <b-button variant="light" style="margin-right: 10px" @click="resetDataLaporanKematian">Reset</b-button>
                  <b-button variant="primary" style="margin-right: 10px">Cetak</b-button>

                  <b-button variant="success" :disabled="busyLaporanKematian || !isValid" @click="saveLaporanKematian">Simpan</b-button>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/id";
import "vue2-datepicker/index.css";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  components: {
    Multiselect,
    DatePicker,
  },
  name: "input_laporan_kematian",
  data() {
    return {
      pencari_data: [],
      query_pasien: "",
      is_data: {
        // no_surat_meninggal, nik_alm, kk_alm, nama_alm, jenis_kelamin_alm, tempat_lahir_alm, tanggal_lahir_alm, alamat_alm, data_pelapor, nama_dusun_alm, ms_kelurahan_id
        tipe_id: "",
        no_surat_meninggal: "",
        nik_alm: "",
        kk_alm: "",
        nama_alm: "",
        jenis_kelamin_alm: "",
        tempat_lahir_alm: "",
        tanggal_lahir_alm: "",  // DATE
        alamat_alm: "",
        data_pelapor: {         // JSON
          nik_pelapor: "",
          nama_pelapor: "",
          tempat_lahir_pelapor: "",
          tanggal_lahir_pelapor: "",
          pekerjaan_pelapor: "",
          alamat_pelapor: "",
          hubungan_pelapor: "",

          tanggal_meninggal: new Date(),
          jam_meninggal: new Date(),
          lokasi_meninggal: "",
          usia_meninggal: "",
        }, 
        nama_dusun_alm: "",
        ms_kelurahan_id: "",
      },

      obj_provinsi: [],
      obj_kota: [],
      obj_kecamatan: [],
      obj_kelurahan: [],

      option_cari_pasien: [],
      is_loading: false,

      option_nik: [],
      option_kk: [],
      option_prov: [],
      option_kabkot: [],
      option_kec: [],
      option_keldes: [],
      option_nik_pelapor: [],

      busyLaporanKematian: false
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
  },
  watch: {
    pencari_data: {
      handler(data) {
        if (data) {
          let x = Object.keys(this.pencari_data).find(key => this.pencari_data[key] == this.query_pasien)
          this.is_data.tipe_id = x == 'nik' ? "KTP" : x == 'no_rm' ? "RM" : x == 'no_bpjs' ? "BPJS" : "-" 
          this.is_data.nik_alm = data.nik
          this.is_data.kk_alm = data.no_kk
          this.is_data.nama_alm = data.nama_lengkap
          this.is_data.jenis_kelamin_alm = data.jenis_kelamin
          this.is_data.tempat_lahir_alm = data.tempat_lahir
          this.is_data.tanggal_lahir_alm = new Date(data.tanggal_lahir)
          this.is_data.nama_dusun_alm = data.nama_dusun
          this.is_data.alamat_alm = data.alamat_sekarang
          // this.obj_kelurahan.ms_kelurahan_id = data.ms_kelurahan_id
          console.log("kelurahan_id", data.ms_kelurahan_id);
          this.hitungUsia()
          if (data.ms_kelurahan_id) {
            this.cariKelurahan(data.ms_kelurahan_id)
          }
        } else {
          this.is_data.tipe_id = ""
          this.is_data.nik_alm = ""
          this.is_data.kk_alm = ""
          this.is_data.nama_alm = ""
          this.is_data.jenis_kelamin_alm = ""
          this.is_data.tempat_lahir_alm = ""
          this.is_data.tanggal_lahir_alm = ""
          this.is_data.nama_dusun_alm = ""
          this.is_data.alamat_alm = ""
          this.obj_provinsi = {}
          this.obj_kota = {}
          this.obj_kecamatan = {}
          this.obj_kelurahan = {}
          this.is_data.data_pelapor.usia_meninggal = ""
        }
      },
      deep: true
    },
  },
  mounted() {
    this.getProvinsi()
    this.getKota()
    this.getKecamatan()
    this.getKelurahan()
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      tipe_id: {},
      no_surat_meninggal: {},
      nik_alm: { required },
      kk_alm: { required },
      nama_alm: { required },
      jenis_kelamin_alm: { required },
      tempat_lahir_alm: { required },
      tanggal_lahir_alm: { required },
      alamat_alm: { required },
      data_pelapor: {
        nik_pelapor: { required },
        nama_pelapor: { required },
        tempat_lahir_pelapor: { required },
        tanggal_lahir_pelapor: { required },
        pekerjaan_pelapor: {},
        alamat_pelapor: { required },
        hubungan_pelapor: { required },

        tanggal_meninggal: { required },
        jam_meninggal: { required },
        lokasi_meninggal: { required },
        usia_meninggal: { required },
      }, 
      nama_dusun_alm: {},
      ms_kelurahan_id: {},
    },
  },
  methods: {
    pindahList() {
      let vm = this;
      vm.$router.push({
        path: "/pasien/pasien_meninggal",
        // query: { id_ba : data.item.id },
      });
    },
    hitungUsia() {
      let a = this.$moment(this.is_data.data_pelapor.tanggal_meninggal);
      let b = this.$moment(this.is_data.tanggal_lahir_alm);

      if (a && b) {
        let diffDuration = this.$moment.duration(a.diff(b));
        this.is_data.data_pelapor.usia_meninggal = `${diffDuration.years()} tahun ${diffDuration.months()} bulan ${diffDuration.days()} hari`;
      }
    },
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    async getProvinsi() {
      const vm = this
      try {
        let provinsi = await vm.$axios.post('/ms_provinsi/list')
        vm.option_prov = provinsi.data.status == 200 ? provinsi.data.data : []
      } catch (err) {
        console.log(err);
      }
    },
    async getKota(val) {
      const vm = this
      let x = val ? { ms_provinsi_id: val.ms_provinsi_id } : null
      try {
        let kota = await vm.$axios.post('/ms_kota/list', x)
        vm.option_kabkot = kota.data.status == 200 ? kota.data.data : []
      } catch (err) {
        console.log(err);
      }
    },
    async getKecamatan(val) {
      const vm = this
      let x = val ? { ms_kota_id: val.ms_kota_id } : null
      try {
        let kecamatan = await vm.$axios.post('/ms_kecamatan/list', x)
        vm.option_kec = kecamatan.data.status == 200 ? kecamatan.data.data : []
      } catch (err) {
        console.log(err);
      }
    },
    async getKelurahan(val) {
      const vm = this
      let x = val ? { ms_kecamatan_id: val.ms_kecamatan_id } : null
      try {
        let kelurahan = await vm.$axios.post('/ms_kelurahan/list', x)
        vm.option_keldes = kelurahan.data.status == 200 ? kelurahan.data.data : []
      } catch (err) {
        console.log(err);
      }
    },
    async cekCariPasien(query) {
      const vm = this
      vm.is_loading = true
      let x = {
        nomor: query
      }
      try {
        let res = await vm.$axios.post('/rm/details_rm_non_bpjs_by_nomor', x)
        if (res.data.status == 200) {
          vm.option_cari_pasien = res.data.data
          vm.query_pasien = query
        } else {
          console.log(res.data);
        }
      } catch (err) {
        console.log(err);
      } finally {
        vm.is_loading = false
      }
    },
    async cariKelurahan(val) {
      const vm = this
      try {
        let res = await vm.$axios('/ms_kelurahan/details_by_id/' + val)
        console.log("cari kelurahan", res);
        let x = res.data.data[0]
        vm.obj_provinsi = vm.option_prov.find(opt => opt.ms_provinsi_id == x.ms_provinsi_id)
        vm.obj_kota = vm.option_kabkot.find(opt => opt.ms_kota_id == x.ms_kab_kota_id)
        vm.obj_kecamatan = vm.option_kec.find(opt => opt.ms_kecamatan_id == x.ms_kecamatan_id)
        vm.obj_kelurahan = vm.option_keldes.find(opt => opt.ms_kelurahan_id == x.ms_kelurahan_id)
      } catch (err) {
        console.log(err);
      }
    },
    async saveLaporanKematian() {
      const vm = this
      vm.busyLaporanKematian = true
      console.log("Laporan", vm.is_data);
      vm.is_data.data_pelapor.nik_pelapor = vm.is_data.data_pelapor.nik
      vm.is_data.ms_kelurahan_id = vm.obj_kelurahan.ms_kelurahan_id
      vm.is_data.no_surat_meninggal = "LK" + vm.$moment().format("DDMMYYHHmmss") + Math.floor(Math.random() * 100) 

      try {
        let res = await vm.$axios.post('/laporan_meninggal/register', vm.is_data)
        console.log(res);
        if (res.data.status == 200 && res.data.message == 'sukses') {
          vm.triggerAlert({
            variant: "success",
            msg: "BERHASIL MENAMBAHKAN DATA",
            showing: true,
          });
          vm.resetDataLaporanKematian()
          vm.pindahList()
        } else {
          // console.log("else", res.data);
          vm.triggerAlert({
            variant: "warning",
            msg: _.toUpper(res.data.message),
            showing: true,
          });
        }
      } catch (err) {
        console.log(err);
        vm.triggerAlert({
          variant: "danger",
          msg: "TERJADI GANGGUAN PADA JARINGAN",
          showing: true,
        });
      } finally {
        vm.busyLaporanKematian = false
      }
    },
    resetDataLaporanKematian() {
      this.$v.$reset();
      this.pencari_data = []
      this.is_data = {
        // no_surat_meninggal, nik_alm, kk_alm, nama_alm, jenis_kelamin_alm, tempat_lahir_alm, tanggal_lahir_alm, alamat_alm, data_pelapor, nama_dusun_alm, ms_kelurahan_id
        tipe_id: "",
        no_surat_meninggal: "",
        nik_alm: "",
        kk_alm: "",
        nama_alm: "",
        jenis_kelamin_alm: "",
        tempat_lahir_alm: "",
        tanggal_lahir_alm: "",  // DATE
        alamat_alm: "",
        data_pelapor: {         // JSON
          nik_pelapor: "",
          nama_pelapor: "",
          tempat_lahir_pelapor: "",
          tanggal_lahir_pelapor: "",
          pekerjaan_pelapor: "",
          alamat_pelapor: "",
          hubungan_pelapor: "",

          tanggal_meninggal: new Date(),
          jam_meninggal: new Date(),
          lokasi_meninggal: "",
          usia_meninggal: "",
        }, 
        nama_dusun_alm: "",
        ms_kelurahan_id: "",
      }
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
<style scoped>
.btn-primary {
  background-color: #9c4098;
}
</style>
